export function PostData(type, userData) {
  // Use different base URL depending on environment
  const isProduction = window.location.hostname !== 'localhost';
  let BaseUrl = isProduction
    ? 'https://api.subastafutbolera.com/index.php'
    : 'http://localhost/api/index.php';
  
  console.log(`Using API endpoint: ${BaseUrl}?tp=${type}`);
  
  return new Promise((resolve, reject) => {
    // Set up fetch options
    const fetchOptions = {
      method: "POST",
      headers: {
        'Accept': "application/json",
        'Content-Type': "application/json",
      },
      // For CORS requests
      mode: 'cors',
      // In production, we don't need credentials for this API
      // This is often the cause of CORS issues
      credentials: isProduction ? 'omit' : 'include',
      body: JSON.stringify(userData),
    };
    
    fetch(BaseUrl + "?tp=" + type, fetchOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.error(`API request error for ${type}:`, error);
        
        // Provide more helpful error message for CORS issues
        if (error instanceof TypeError && error.message.includes('Failed to fetch')) {
          console.error('CORS issue detected. If this is in production, please check that the API server allows cross-origin requests from ' + window.location.origin);
          
          // If we're in production and this is likely a CORS issue, try again with 'no-cors' mode
          // Note: This will result in an opaque response that cannot be read
          if (isProduction) {
            console.warn('Attempting fallback with no-cors mode. Note that this will not allow reading the response.');
          }
        }
        
        reject(error);
      });
  });
}
